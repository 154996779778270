// @ts-nocheck

import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: '') => FooterLinkType[] = (t) => [
  {

    items: [
      {
        label: t('Leaderboard'),
        href: '/leaderboard',
        isHighlighted: true,
      },


    ],
  },

]
