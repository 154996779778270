// global.tsx
import { createGlobalStyle } from 'styled-components';
import { PancakeTheme } from '@pancakeswap/uikit';

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  /* Add the new styles */
  .appContainer {
    position: relative;
    min-height: 100vh;
  }

  .pageContent {
    padding-bottom: 80px; /* Same height as the MusicPlayer component */
  }




`;

export default GlobalStyle;
