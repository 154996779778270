// @ts-nocheck


import { BigNumber } from '@ethersproject/bignumber'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { serializeTokens } from 'utils/serializeTokens'
import { bscTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens(bscTokens)

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto CAKE</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Stake CAKE</Trans>,
    description: <Trans>Stake, Earn – And more!</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeFlexibleSideVault]: {
    name: <Trans>Flexible CAKE</Trans>,
    description: <Trans>Flexible staking on the side.</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.IfoPool]: {
    name: 'IFO CAKE',
    description: <Trans>Stake CAKE to participate in IFOs</Trans>,
    autoCompoundFrequency: 1,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
    },
  },
} as const

export const livePools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cake,
    contractAddress: {
      97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
      56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
  },
  {
    sousId: 294,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ankr,
    trader: 'HsakaTrades',
    contractAddress: {
      56: '0x985a20998c7c59e98005c556393215e39c9a4978',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '6.0763',
    version: 3,
  },
  {
    sousId: 293,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.hay,
    contractAddress: {
      56: '0x8cBCf2f2be93D154be5135f465369Ee6dD84314B',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.2604',
    version: 3,
  },

  {
    sousId: 291,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pstake,
    contractAddress: {
      56: '0x56D6955Ba6404647191DD7A5D65A5c9Fe43905e1',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '1.1574',
    version: 3,
  },




]



// known finished pools
const finishedPools = [
  {
    sousId: 292,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.sfund,
    trader:'KeyboardMonkey3',
    contractAddress: {
      56: '0x449EC6B2779A3F63E6c2527CBB162A38Ef199d7D',
      97: '',
    },
    poolCategory: 'KeyboardMonkey3',
    tokenPerBlock: '0.1071',
    version: 3,
  },
  {
    sousId: 290,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.peel,
    trader:'SecretsOfCrypto',
    contractAddress: {
      56: '0x288d1aD79c113552B618765B4986f7DE679367Da',
      97: '',
    },
    poolCategory: 'SecretsOfCrypto',
    tokenPerBlock: '4.34',
    version: 3,
  },
  {
    sousId: 289,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.shell,
    trader:'gainzxbt',

    contractAddress: {
      56: '0x595B7AF4F1828AB4953792482b01B2AFC4A46b72',
      97: '',
    },
    poolCategory: 'gainzxbt',
    tokenPerBlock: '89.699',
    version: 3,
  },
  {
    sousId: 288,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.high,
    trader:'Cryptopathic',

    contractAddress: {
      56: '0x28cc814bE3B994187B7f8Bfed10516A84A671119',
      97: '',
    },
    poolCategory: 'Cryptopathic',
    tokenPerBlock: '0.1332',
    version: 3,
  },
  {
    sousId: 287,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ole,
    trader:'LomahCrypto',

    contractAddress: {
      56: '0xda6F750be1331963E5772BEe757062f6bddcEA4C',
      97: '',
    },
    poolCategory: 'LomahCrypto',
    tokenPerBlock: '2.2569',
    version: 3,
  },
  {
    sousId: 286,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.trivia,
    trader:'_SMFX_',
    contractAddress: {
      56: '0x86471019Bf3f403083390AC47643062e15B0256e',
      97: '',
    },
    poolCategory: '_SMFX',
    tokenPerBlock: '4.976',
    version: 3,
  },
  {
    sousId: 285,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.sdao,
    trader:'LilMoonLambo',
    contractAddress: {
      56: '0x168eF2e470bfeAEB32BE52FB218A41483904851c',
      97: '',
    },
    poolCategory: 'LilMoonLambo',
    tokenPerBlock: '0.405',
    version: 3,
  },
  {
    sousId: 284,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.antex,
    trader:'CryptoUB',
    contractAddress: {
      97: '',
      56: '0xdC37A2B2A6a62008beEe029E36153dF8055a8ADa',
    },
    poolCategory: 'CryptoUB',
    tokenPerBlock: '1937.62',
    version: 3,
  },

  {
    sousId: 283,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xcn,
    trader:'CryptoHornHairs',
    contractAddress: {
      97: '',
      56: '0xa79D37ce9DF9443eF4B6DEC2e38a8ecd35303adc',
    },
    poolCategory: 'CryptoHornHairs',
    tokenPerBlock: '2.2505',
    version: 3,
  },
  {
    sousId: 282,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.chr,
    trader:'RNR_0',
    contractAddress: {
      97: '',
      56: '0x2D17ec6cd0AF737B2adE40ea527d41ceEedc166f',
    },
    poolCategory: 'RNR_0',
    tokenPerBlock: '1.58',
    version: 3,
  },
  {
    sousId: 281,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.MIX,
    trader:'CanteringClark',
    contractAddress: {
      97: '',
      56: '0x0F96E19Bdc787e767BA1e8F1aDD0f62cbdad87C8',
    },
    poolCategory: 'CanteringClark',
    tokenPerBlock: '63.136',
    version: 3,
  },
  {
    sousId: 280,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.metis,
    trader:'AngeloBTC',
    contractAddress: {
      97: '',
      56: '0xC0A94bFF88EdCae7D5d79294C0e9954Ed75CBCb7',
    },
    poolCategory: 'AngeloBTC',
    tokenPerBlock: '0.01331',
    version: 3,
  },
  {
    sousId: 279,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.gal,
    trader:'ByzGeneral',
    contractAddress: {
      97: '',
      56: '0xa5D57C5dca083a7051797920c78fb2b19564176B',
    },
    poolCategory: 'ByzGeneral',
    tokenPerBlock: '0.09645',
    version: 3,
  },
  {
    sousId: 278,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.rpg,
    trader:'Crypto_Ed_NL',
    contractAddress: {
      97: '',
      56: '0xD1c395BCdC2d64ac6544A34A36185483B00530a1',
    },
    poolCategory:'Crypto_Ed_NL',
    tokenPerBlock: '0.06794',
    version: 3,
  },
  {
    sousId: 277,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ankr,
    trader:'loomdart',
    contractAddress: {
      97: '',
      56: '0xc581345e1648CcE154978eA80bF8A584EC8aFDe0',
    },
    poolCategory: 'loomdart',
    tokenPerBlock: '4.6296',
    version: 3,
  },
  {
    sousId: 276,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ceek,
    trader:'johnnie_jacks',
    contractAddress: {
      97: '',
      56: '0xED53944b1c0cEecDe1a413fDb4D0496e1a08ab58',
    },
    poolCategory: 'johnnie_jacks',
    tokenPerBlock: '0.8078',
    version: 3,
  },
  {
    sousId: 275,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tinc,
    trader:'abetrade',
    contractAddress: {
      97: '',
      56: '0x9593462fF51A14633b243Ba3d054A8183d057A02',
    },
    poolCategory: 'abetrade',
    tokenPerBlock: '0.4677',
    version: 3,
  },
  {
    sousId: 274,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pex,
    trader:'Inmortalcrypto',
    contractAddress: {
      97: '',
      56: '0x641B1F2781B34a493E4308A0A3F1c7E042A9B952',
    },
    poolCategory: 'Inmortalcrypto',
    tokenPerBlock: '0.3865',
    version: 3,
  },
  {
    sousId: 273,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.gmi,
    trader:'Timeless_Crypto',
    contractAddress: {
      97: '',
      56: '0x0D53E0f2Eb384777442e4EB813d8f5fAcC742206',
    },
    poolCategory: 'Timeless_Crypto',
    tokenPerBlock: '17.939',
    version: 3,
  },

  {
    sousId: 271,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bsw,
    trader:'depression2019',
    contractAddress: {
      97: '',
      56: '0x7092e029E4ce660F9AC081BF6D8a339BE602398b',
    },
    poolCategory:'depression2019',
    tokenPerBlock: '1.157',
    version: 3,
  },
  {
    sousId: 270,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.duet,
    trader:'KoroushAK',
    contractAddress: {
      97: '',
      56: '0xA581349F26dE887700045F9B7d148775d422fDA2',
    },
    poolCategory: 'KoroushAK',
    tokenPerBlock: '0.4861',
    version: 3,
  },
  {
    sousId: 269,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.gmt,
    trader:'crypto_iso',
    contractAddress: {
      97: '',
      56: '0xe76a31cF974BA5819ce86cB4667a4bf05174bC59',
    },
    poolCategory: 'crypto_iso',
    tokenPerBlock: '4.629',
    version: 3,
  },
  {
    sousId: 268,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xwg,
    trader:'devchart',
    contractAddress: {
      97: '',
      56: '0x6e0272A70075f6782F6842730107E9ABf74C5CC7',
    },
    poolCategory: 'devchart',
    tokenPerBlock: '5.106',
    version: 3,
  },
  {
    sousId: 267,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.high,
    trader:'VentureCoinist',
    contractAddress: {
      97: '',
      56: '0x60c4998C058BaC8042712B54E7e43b892Ab0B0c4',
    },
    poolCategory: 'VentureCoinist',
    tokenPerBlock: '0.09756',
    version: 3,
  },
  {
    sousId: 266,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.thg,
    trader:'Anbessa100',
    contractAddress: {
      97: '',
      56: '0xD1D03A3D4C27884a8703Cdb78504737C9E9A159e',
    },
    poolCategory: 'Anbessa100',
    tokenPerBlock: '0.162',
    version: 3,
  },
  {
    sousId: 265,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.era,
    trader:'ThinkingBitmex',
    contractAddress: {
      97: '',
      56: '0x260F95f5b7FD8eda720ED9d0829164dE35B048ab',
    },
    poolCategory: 'ThinkingBitmex',
    tokenPerBlock: '1.608',
    version: 3,
  },
  {
    sousId: 264,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.btt,
    trader:'CryptOrca',
    contractAddress: {
      97: '',
      56: '0x346a1b672C5Cbb6AE21715428f77A0049B29B332',
    },
    poolCategory: 'CryptOrca',
    tokenPerBlock: '157829',
  },
  {
    sousId: 263,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ach,
    trader:'Cryptodude999',
    contractAddress: {
      97: '',
      56: '0xcD1Be742b04DB005e2C445A11BDe6d13DD9dd454',
    },
    poolCategory: 'Cryptodude999',
    tokenPerBlock: '7.502',
  },
  {
    sousId: 261,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.sfund,
    trader:'TraderKoz',
    contractAddress: {
      97: '',
      56: '0x80762101bd79D6e7A175E9678d05c7f815b8D7d7',
    },
    poolCategory: 'TraderKoz',
    tokenPerBlock: '0.06481',
  },
  {
    sousId: 260,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.raca,
    trader:'HighStakesCap',
    contractAddress: {
      97: '',
      56: '0xAaF43935a526DF88AB57FC69b1d80a8d35e1De82',
    },
    poolCategory: 'HighStakesCap',
    tokenPerBlock: '204.2',
  },
  {
    sousId: 259,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ertha,
    trader:'cryptomocho',
    contractAddress: {
      97: '',
      56: '0x921Ea7e12A66025F2BD287eDbff6dc5cEABd6477',
    },
    poolCategory: 'cryptomocho',
    tokenPerBlock: '1.585',
  },
  {
    sousId: 258,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.fuse,
    trader:'AltcoinSherpa',
    contractAddress: {
      97: '',
      56: '0xeAd7b8fc5F2E5672FAe9dCf14E902287F35CB169',
    },
    poolCategory: 'AltcoinSherpa',
    tokenPerBlock: '0.19',
  },
  {
    sousId: 257,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.froyo,
    trader:'exitscammed',
    contractAddress: {
      97: '',
      56: '0x1c9E3972fdBa29b40954Bb7594Da6611998F8830',
    },
    poolCategory: 'exitscammed',
    tokenPerBlock: '2.893',
  },
  {
    sousId: 256,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.aog,
    trader:'icebergy_',
    contractAddress: {
      97: '',
      56: '0xa34832efe74133763A85060a64103542031B0A7E',
    },
    poolCategory: 'icebergy_',
    tokenPerBlock: '0.6435',
  },
  {
    sousId: 255,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.apx,
    trader:'ColdBloodShill',
    contractAddress: {
      97: '',
      56: '0x92c07c325cE7b340Da2591F5e9CbB1F5Bab73FCF',
    },
    poolCategory: 'ColdBloodShill',
    tokenPerBlock: '3.035',
  },
  {
    sousId: 254,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bcoin,
    trader:'MoonOverlord',
    contractAddress: {
      97: '',
      56: '0x25ca61796d786014ffe15e42ac11c7721d46e120',
    },
    poolCategory: 'MoonOverlord',
    tokenPerBlock: '0.1493',
  },
  {
    sousId: 253,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bcoin,
    trader:'incomesharks',
    contractAddress: {
      97: '',
      56: '0xad8F6A9d58012DCa2303226B287E80e5fE27eff0',
    },
    poolCategory: 'incomesharks',
    tokenPerBlock: '0.1493',
  },
  {
    sousId: 252,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.insur,
    trader:'Murfski_',
    contractAddress: {
      97: '',
      56: '0x1A777aE604CfBC265807A46Db2d228d4CC84E09D',
    },
    poolCategory: 'Murfski_',
    tokenPerBlock: '0.3281',
  },
  {
    sousId: 251,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.gm,
    trader:'StackingUSD',
    contractAddress: {
      97: '',
      56: '0x09e727c83a75fFdB729280639eDBf947dB76EeB7',
    },
    poolCategory: 'StackingUSD',
    tokenPerBlock: '7893',
  },
  {
    sousId: 250,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.woop,
    trader:'Lord_Ashdrake',
    contractAddress: {
      97: '',
      56: '0x2718D56aE2b8F08B3076A409bBF729542233E451',
    },
    poolCategory: 'Lord_Ashdrake',
    tokenPerBlock: '1.226',
  },
  {
    sousId: 249,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.high,
    trader:'CryptoCred',
    contractAddress: {
      97: '',
      56: '0x2461ea28907A2028b2bCa40040396F64B4141004',
    },
    poolCategory: 'CryptoCred',
    tokenPerBlock: '0.02679',
  },
  {
    sousId: 247,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dpt,
    trader:'Yodaskk',
    contractAddress: {
      97: '',
      56: '0x1c0C7F3B07a42efb4e15679a9ed7e70B2d7Cc157',
    },
    poolCategory: 'Yodaskk',
    tokenPerBlock: '4.62962963',
  },
  {
    sousId: 246,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.thg,
    trader:'ShardiB2',
    contractAddress: {
      97: '',
      56: '0x56Bfb98EBEF4344dF2d88c6b80694Cba5EfC56c8',
    },
    poolCategory: 'ShardiB2',
    tokenPerBlock: '0.03761',
  },
  {
    sousId: 245,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.idia,
    trader:'Pentosh1',
    contractAddress: {
      97: '',
      56: '0x07984aBb7489CD436d27875c07Eb532d4116795a',
    },
    poolCategory: 'Pentosh1',
    tokenPerBlock: '0.1262',
  },
  {
    sousId: 244,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xcv,
    trader:'cryptunez',
    contractAddress: {
      97: '',
      56: '0xF1fA41f593547E406a203b681df18acCC3971A43',
    },
    poolCategory: 'cryptunez',
    tokenPerBlock: '0.7413',
  },
  {
    sousId: 243,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.nabox,
    trader:'TraderNJ1',
    contractAddress: {
      97: '',
      56: '0x13A40BFab005D9284f8938FBb70Bf39982580e4D',
    },
    poolCategory: 'TraderNJ1',
    tokenPerBlock: '1271',
  },
  {
    sousId: 242,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.santos,
    trader:'CryptoKaleo',
    contractAddress: {
      97: '',
      56: '0x0914b2d9D4DD7043893DEF53ecFC0F1179F87d5c',
    },
    poolCategory: 'CryptoKaleo',
    tokenPerBlock: '0.0578',
  },
  {
    sousId: 241,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.quidd,
    trader:'LSDinmycoffee',
    contractAddress: {
      97: '',
      56: '0xd97ee2bfe79a4d4ab388553411c462fbb536a88c',
    },
    poolCategory: 'LSDinmycoffee',
    tokenPerBlock: '0.07471',
  },
  {
    sousId: 240,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.zoo,
    trader:'imCryptoGoku',
    contractAddress: {
      97: '',
      56: '0x2EfE8772EB97B74be742d578A654AB6C95bF18db',
    },
    poolCategory: 'imCryptoGoku',
    tokenPerBlock: '0.4516',
  },
  {
    sousId: 239,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.sfund,
    trader:'SmokeyHosoda',
    contractAddress: {
      97: '',
      56: '0x7F103689cabe17C2F70DA6faa298045d72a943b8',
    },
    poolCategory: 'SmokeyHosoda',
    tokenPerBlock: '0.0311',
  },
  {
    sousId: 238,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.qi,
    trader:'blackbeardXBT',
    contractAddress: {
      97: '',
      56: '0xbd52ef04DB1ad1c68A8FA24Fa71f2188978ba617',
    },
    poolCategory: 'blackbeardXBT',
    tokenPerBlock: '1.736',
  },
  {
    sousId: 237,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.kart,
    trader:'Pladizow',
    contractAddress: {
      97: '',
      56: '0x73bB10B89091f15e8FeD4d6e9EBa6415df6acb21',
    },
    poolCategory: 'Pladizow',
    tokenPerBlock: '0.1897',
  },
  {
    sousId: 236,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.porto,
    trader:'Bluntz_Capital',
    contractAddress: {
      97: '',
      56: '0xdD52FAB121376432DBCBb47592742F9d86CF8952',
    },
    poolCategory: 'Bluntz_Capital',
    tokenPerBlock: '0.0578',
  },
  {
    sousId: 235,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dvi,
    trader:'George1Trader',
    contractAddress: {
      97: '',
      56: '0x2b8751B7141Efa7a9917f9C6fea2CEA071af5eE7',
    },
    poolCategory: 'George1Trader',
    tokenPerBlock: '0.2516',
  },
  {
    sousId: 234,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.jgn,
    trader:'KRTrades_',
    contractAddress: {
      97: '',
      56: '0xfDFb4DbE94916F9f55dBC2c14Ea8B3e386eCD9F9',
    },
    poolCategory: 'KRTrades_',
    tokenPerBlock: '0.5233',
  },
  {
    sousId: 232,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.xwg,
    trader:'HsakaTrades',
    contractAddress: {
      97: '',
      56: '0x79f5f7DDADeFa0A9e850DFFC4fBa77e5172Fe701',
    },
    poolCategory: 'HsakaTrades',
    tokenPerBlock: '2.3379',
  },

  {
    sousId: 230,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.fina,
    trader:'Crypto_Chase',
    contractAddress: {
      97: '',
      56: '0xa35caA9509a2337E22C54C929146D5F7f6515794',
    },
    poolCategory: 'Crypto_Chase',
    tokenPerBlock: '0.06944',
  },
  {
    sousId: 229,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bmon,
    trader:'bitbitcrypto',
    contractAddress: {
      97: '',
      56: '0x6e63B2B96c77532ea7ec2B3D3BFA9C8e1d383f3C',
    },
    poolCategory: 'bitbitcrypto',
    tokenPerBlock: '2.0254',
  },
  {
    sousId: 228,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.dkt,
    trader:'NyuuRoe',
    contractAddress: {
      97: '',
      56: '0xFef4B7a0194159d89717Efa592384d42B28D3926',
    },
    poolCategory: 'NyuuRoe',
    tokenPerBlock: '0.09838',
  },
  {
    sousId: 227,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ramp,
    trader:'jimtalbot',
    contractAddress: {
      97: '',
      56: '0x2D26e4b9a5F19eD5BB7AF221DC02432D31DEB4dA',
    },
    poolCategory: 'jimtalbot',
    tokenPerBlock: '1.2152',
  },
  {
    sousId: 226,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.lazio,
    trader:'MacroCRG',
    contractAddress: {
      97: '',
      56: '0xd008416c2c9cf23843bd179aa3cefedb4c8d1607',
    },
    poolCategory: 'MacroCRG',
    tokenPerBlock: '0.0694',
  },
  {
    sousId: 225,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.sps,
    trader:'CryptoNewton',
    contractAddress: {
      97: '',
      56: '0xd9b63bb6c62fe2e9a641699a91e680994b8b0081',
    },
    poolCategory: 'CryptoNewton',
    tokenPerBlock: '0.40046',
  },
  {
    sousId: 224,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.mcb,
    trader:'GiganticRebirth',
    contractAddress: {
      97: '',
      56: '0xCc2D359c3a99d9cfe8e6F31230142efF1C828e6D',
    },
    poolCategory: 'GiganticRebirth',
    tokenPerBlock: '0.00706',
  },
  {
    sousId: 223,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.rpg,
    trader:'TheCryptoDog',
    contractAddress: {
      97: '',
      56: '0x65C0940C50A3C98AEEc95a115Ae62E9804588713',
    },
    poolCategory: 'TheCryptoDog',
    tokenPerBlock: '0.01863',
  },
  {
    sousId: 222,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.beta,
    trader:'edwardmorra_btc',
    contractAddress: {
      97: '',
      56: '0x6f660c58723922c6f866a058199ff4881019b4b5',
    },
    poolCategory: 'edwardmorra_btc',
    tokenPerBlock: '1.7361',
  },
  {
    sousId: 233,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.eternal,
    trader:'btc_charlie',
    contractAddress: {
      97: '',
      56: '0xc28c400F2B675b25894FA632205ddec71E432288',
    },
    poolCategory: 'btc_charlie',
    tokenPerBlock: '0.002893',
  },
  {
    sousId: 221,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.nft,
    trader:'Captain_Kole1',
    contractAddress: {
      97: '',
      56: '0x8d018823d13c56d62ffb795151a9e629c21e047b',
    },
    poolCategory: 'Captain_Kole1',
    tokenPerBlock: '173727',
  },
  {
    sousId: 220,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.chess,
    trader:'DaanCrypto',
    contractAddress: {
      97: '',
      56: '0x4D1Ec426d0d7fb6bF344Dd372d0502EDD71c8d88',
    },
    poolCategory: 'DaanCrypto',
    tokenPerBlock: '0.0861',
  },
  {
    sousId: 219,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tlos,
    trader:'SalsaTekila',
    contractAddress: {
      97: '',
      56: '0xCB41a72067c227D6Ed7bc7CFAcd13eCe47Dfe5E9',
    },
    poolCategory: 'SalsaTekila',
    tokenPerBlock: '0.3616',
  },
  {
    sousId: 218,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.stephero,
    trader:'ThinkingUSD',
    contractAddress: {
      97: '',
      56: '0xcecba456fefe5b18d43df23419e7ab755b436655',
    },
    poolCategory:'ThinkingUSD',
    tokenPerBlock: '0.1851',
  },
  {
    sousId: 217,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bp,
    trader:'CryptoCapo_',
    contractAddress: {
      97: '',
      56: '0x8ed7acf12b08274d5cdaf03d43d0e54bcbdd487e',
    },
    poolCategory: 'CryptoCapo_',
    tokenPerBlock: '0.217',
  },
  {
    sousId: 216,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cvp,
    trader:'Dennahz',
    contractAddress: {
      97: '',
      56: '0xC4b15117BC0be030c20754FF36197641477af5d1',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.14',
  },
  {
    sousId: 215,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.qbt,
    trader:'Ninjascalp',
    contractAddress: {
      97: '',
      56: '0xB72dEf58D0832f747d6B7197471Fe20AeA7EB463',
    },
    poolCategory: 'Ninjascalp',
    tokenPerBlock: '1.412',
  },
  {
    sousId: 214,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.naos,
    trader:'AltcoinPsycho',
    contractAddress: {
      97: '',
      56: '0xb38b78529bCc895dA16CE2978D6cD6C56e8CfFC3',
    },
    poolCategory: 'AltcoinPsycho',
    tokenPerBlock: '0.1736',
  },
  {
    sousId: 213,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pha,
    trader:'cubantobacco',
    contractAddress: {
      97: '',
      56: '0x2E101b5F7f910F2609e5AcE5f43bD274b1DE09AA',
    },
    poolCategory: 'cubantobacco',
    tokenPerBlock: '0.28',
  },
  {
    sousId: 212,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bel,
    trader:'hentaiavenger66',
    contractAddress: {
      97: '',
      56: '0x52733Ad7b4D09BF613b0389045e33E2F287afa04',
    },
    poolCategory: 'hentaiavenger66',
    tokenPerBlock: '0.101',
  },
  {
    sousId: 211,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.ramp,
    trader:'Etherean007',
    contractAddress: {
      97: '',
      56: '0x401b9b97bdbc3197c1adfab9652dc78040bd1e13',
    },
    poolCategory: 'Etherean007',
    tokenPerBlock: '0.9837',
  },
  {
    sousId: 210,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.pots,
    trader:'ThisIsNuse',
    contractAddress: {
      97: '',
      56: '0xBeDb490970204cb3CC7B0fea94463BeD67d5364D',
    },
    poolCategory: 'ThisIsNuse',
    tokenPerBlock: '0.0868',
  },
  {
    sousId: 209,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.tusd,
    trader:'SMtrades_',
    contractAddress: {
      97: '',
      56: '0xb6e510ae2da1ab4e350f837c70823ab75091780e',
    },
    poolCategory: 'SMtrades_',
    tokenPerBlock: '0.5787',
  },
  {
    sousId: 208,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.revv,
    trader:'ksicrypto',
    contractAddress: {
      97: '',
      56: '0x8aa5b2c67852ed5334c8a7f0b5eb0ef975106793',
    },
    poolCategory: 'ksicrypto',
    tokenPerBlock: '1.331',
  },
  {
    sousId: 207,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.bttold,
    trader:'pierre_crypt0',
    contractAddress: {
      97: '',
      56: '0x3b804460c3c62f0f565af593984159f13b1ac976',
    },
    poolCategory: 'pierre_crypt0',
    tokenPerBlock: '263',
  },
  {
    sousId: 206,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.skill,
    trader:'TraderNikow',
    contractAddress: {
      97: '',
      56: '0x455f4d4cc4d6ca15441a93c631e82aaf338ad843',
    },
    poolCategory: 'TraderNikow',
    tokenPerBlock: '0.00289',
  },
  {
    sousId: 205,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.win,
    trader:'Tradermayne',
    contractAddress: {
      97: '',
      56: '0xde4aef42bb27a2cb45c746acde4e4d8ab711d27c',
    },
    poolCategory: 'Tradermayne',
    tokenPerBlock: '1815.39',
  },
  {
    sousId: 204,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.if,
    trader:'TheLordofBTC',
    contractAddress: {
      97: '',
      56: '0x57d3524888ded4085d9124a422f13b27c8a43de7',
    },
    poolCategory: 'TheLordofBTC',
    tokenPerBlock: '0.02835',
  },
  {
  sousId: 203,
  stakingToken: serializedTokens.cake,
  earningToken: serializedTokens.swingby,
  trader:'test1',
  contractAddress: {
    97: '',
    56: '0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739',
  },
  poolCategory: 'test1',
  tokenPerBlock: '0.02835',
},
{
  sousId: 202,
  stakingToken: serializedTokens.cake,
  earningToken: serializedTokens.if,
  trader:'test2',
  contractAddress: {
    97: '',
    56: '0x57d3524888ded4085d9124a422f13b27c8a43de7',
  },
  poolCategory: 'test2',
  tokenPerBlock: '0.02835',
},
{
  sousId: 201,
  stakingToken: serializedTokens.cake,
  earningToken: serializedTokens.if,
  trader:'test3',
  contractAddress: {
    97: '',
    56: '0x57d3524888ded4085d9124a422f13b27c8a43de7',
  },
  poolCategory: 'test3',
  tokenPerBlock: '0.02835',
},
{
	sousId: 200,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test4',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test4',
	tokenPerBlock: 0.02835
}, {
	sousId: 199,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test5',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test5',
	tokenPerBlock: 0.02835
}, {
	sousId: 198,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test6',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test6',
	tokenPerBlock: 0.02835
}, {
	sousId: 197,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test7',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test7',
	tokenPerBlock: 0.02835
}, {
	sousId: 196,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test8',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test8',
	tokenPerBlock: 0.02835
}, {
	sousId: 195,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test9',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test9',
	tokenPerBlock: 0.02835
}, {
	sousId: 194,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test10',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test10',
	tokenPerBlock: 0.02835
}, {
	sousId: 193,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test11',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test11',
	tokenPerBlock: 0.02835
}, {
	sousId: 192,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test12',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test12',
	tokenPerBlock: 0.02835
}, {
	sousId: 191,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test13',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test13',
	tokenPerBlock: 0.02835
}, {
	sousId: 190,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test14',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test14',
	tokenPerBlock: 0.02835
}, {
	sousId: 189,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test15',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test15',
	tokenPerBlock: 0.02835
}, {
	sousId: 188,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test16',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test16',
	tokenPerBlock: 0.02835
}, {
	sousId: 187,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test17',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test17',
	tokenPerBlock: 0.02835
}, {
	sousId: 186,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test18',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test18',
	tokenPerBlock: 0.02835
}, {
	sousId: 185,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test19',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test19',
	tokenPerBlock: 0.02835
}, {
	sousId: 184,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test20',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test20',
	tokenPerBlock: 0.02835
}, {
	sousId: 183,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test21',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test21',
	tokenPerBlock: 0.02835
}, {
	sousId: 182,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test22',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test22',
	tokenPerBlock: 0.02835
}, {
	sousId: 181,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test23',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test23',
	tokenPerBlock: 0.02835
}, {
	sousId: 180,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test24',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test24',
	tokenPerBlock: 0.02835
}, {
	sousId: 179,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test25',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test25',
	tokenPerBlock: 0.02835
}, {
	sousId: 178,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test26',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test26',
	tokenPerBlock: 0.02835
}, {
	sousId: 177,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test27',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test27',
	tokenPerBlock: 0.02835
}, {
	sousId: 176,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test28',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test28',
	tokenPerBlock: 0.02835
}, {
	sousId: 175,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test29',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test29',
	tokenPerBlock: 0.02835
}, {
	sousId: 174,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test30',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test30',
	tokenPerBlock: 0.02835
}, {
	sousId: 173,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test31',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test31',
	tokenPerBlock: 0.02835
}, {
	sousId: 172,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test32',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test32',
	tokenPerBlock: 0.02835
}, {
	sousId: 171,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test33',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test33',
	tokenPerBlock: 0.02835
}, {
	sousId: 170,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test34',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test34',
	tokenPerBlock: 0.02835
}, {
	sousId: 169,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test35',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test35',
	tokenPerBlock: 0.02835
}, {
	sousId: 168,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test36',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test36',
	tokenPerBlock: 0.02835
}, {
	sousId: 167,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test37',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test37',
	tokenPerBlock: 0.02835
}, {
	sousId: 166,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test38',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test38',
	tokenPerBlock: 0.02835
}, {
	sousId: 165,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test39',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test39',
	tokenPerBlock: 0.02835
}, {
	sousId: 164,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test40',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test40',
	tokenPerBlock: 0.02835
}, {
	sousId: 163,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test41',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test41',
	tokenPerBlock: 0.02835
}, {
	sousId: 162,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test42',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test42',
	tokenPerBlock: 0.02835
}, {
	sousId: 161,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test43',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test43',
	tokenPerBlock: 0.02835
}, {
	sousId: 160,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test44',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test44',
	tokenPerBlock: 0.02835
}, {
	sousId: 159,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test45',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test45',
	tokenPerBlock: 0.02835
}, {
	sousId: 158,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test46',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test46',
	tokenPerBlock: 0.02835
}, {
	sousId: 157,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test47',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test47',
	tokenPerBlock: 0.02835
}, {
	sousId: 156,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test48',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test48',
	tokenPerBlock: 0.02835
}, {
	sousId: 155,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test49',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test49',
	tokenPerBlock: 0.02835
}, {
	sousId: 154,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test50',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test50',
	tokenPerBlock: 0.02835
}, {
	sousId: 153,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test51',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test51',
	tokenPerBlock: 0.02835
}, {
	sousId: 152,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test52',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test52',
	tokenPerBlock: 0.02835
}, {
	sousId: 151,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test53',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test53',
	tokenPerBlock: 0.02835
}, {
	sousId: 150,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test54',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test54',
	tokenPerBlock: 0.02835
}, {
	sousId: 149,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test55',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test55',
	tokenPerBlock: 0.02835
}, {
	sousId: 148,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test56',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test56',
	tokenPerBlock: 0.02835
}, {
	sousId: 147,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test57',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test57',
	tokenPerBlock: 0.02835
}, {
	sousId: 146,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test58',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test58',
	tokenPerBlock: 0.02835
}, {
	sousId: 145,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test59',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test59',
	tokenPerBlock: 0.02835
}, {
	sousId: 144,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test60',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test60',
	tokenPerBlock: 0.02835
}, {
	sousId: 143,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test61',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test61',
	tokenPerBlock: 0.02835
}, {
	sousId: 142,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test62',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test62',
	tokenPerBlock: 0.02835
}, {
	sousId: 141,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test63',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test63',
	tokenPerBlock: 0.02835
}, {
	sousId: 140,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test64',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test64',
	tokenPerBlock: 0.02835
}, {
	sousId: 139,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test65',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test65',
	tokenPerBlock: 0.02835
}, {
	sousId: 138,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test66',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test66',
	tokenPerBlock: 0.02835
}, {
	sousId: 137,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test67',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test67',
	tokenPerBlock: 0.02835
}, {
	sousId: 136,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test68',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test68',
	tokenPerBlock: 0.02835
}, {
	sousId: 135,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test69',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test69',
	tokenPerBlock: 0.02835
}, {
	sousId: 134,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test70',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test70',
	tokenPerBlock: 0.02835
}, {
	sousId: 133,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test71',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test71',
	tokenPerBlock: 0.02835
}, {
	sousId: 132,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test72',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test72',
	tokenPerBlock: 0.02835
}, {
	sousId: 131,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test73',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test73',
	tokenPerBlock: 0.02835
}, {
	sousId: 130,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test74',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test74',
	tokenPerBlock: 0.02835
}, {
	sousId: 129,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test75',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test75',
	tokenPerBlock: 0.02835
}, {
	sousId: 128,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test76',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test76',
	tokenPerBlock: 0.02835
}, {
	sousId: 127,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test77',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test77',
	tokenPerBlock: 0.02835
}, {
	sousId: 126,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test78',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test78',
	tokenPerBlock: 0.02835
}, {
	sousId: 125,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test79',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test79',
	tokenPerBlock: 0.02835
}, {
	sousId: 124,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test80',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test80',
	tokenPerBlock: 0.02835
}, {
	sousId: 123,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test81',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test81',
	tokenPerBlock: 0.02835
}, {
	sousId: 122,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test82',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test82',
	tokenPerBlock: 0.02835
}, {
	sousId: 121,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test83',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test83',
	tokenPerBlock: 0.02835
}, {
	sousId: 120,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test84',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test84',
	tokenPerBlock: 0.02835
}, {
	sousId: 119,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test85',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test85',
	tokenPerBlock: 0.02835
}, {
	sousId: 118,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test86',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test86',
	tokenPerBlock: 0.02835
}, {
	sousId: 117,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test87',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test87',
	tokenPerBlock: 0.02835
}, {
	sousId: 116,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test88',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test88',
	tokenPerBlock: 0.02835
}, {
	sousId: 115,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test89',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test89',
	tokenPerBlock: 0.02835
}, {
	sousId: 114,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test90',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test90',
	tokenPerBlock: 0.02835
}, {
	sousId: 113,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test91',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test91',
	tokenPerBlock: 0.02835
}, {
	sousId: 112,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test92',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test92',
	tokenPerBlock: 0.02835
}, {
	sousId: 111,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test93',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test93',
	tokenPerBlock: 0.02835
}, {
	sousId: 110,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test94',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test94',
	tokenPerBlock: 0.02835
}, {
	sousId: 109,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test95',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test95',
	tokenPerBlock: 0.02835
}, {
	sousId: 108,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test96',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test96',
	tokenPerBlock: 0.02835
}, {
	sousId: 107,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test97',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test97',
	tokenPerBlock: 0.02835
}, {
	sousId: 106,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test98',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test98',
	tokenPerBlock: 0.02835
}, {
	sousId: 105,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test99',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test99',
	tokenPerBlock: 0.02835
}, {
	sousId: 104,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test100',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test100',
	tokenPerBlock: 0.02835
}, {
	sousId: 103,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test101',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test101',
	tokenPerBlock: 0.02835
}, {
	sousId: 102,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test102',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test102',
	tokenPerBlock: 0.02835
}, {
	sousId: 101,
	stakingToken: serializedTokens.cake,
	earningToken: serializedTokens.if,
	trader: 'test103',
	contractAddress: {
		97: '',
		56: '0x57d3524888ded4085d9124a422f13b27c8a43de7'
	},
	poolCategory: 'test103',
	tokenPerBlock: 0.02835
},





].map((p) => ({ ...p, isFinished: true }))

export default [...livePools, ...finishedPools]
