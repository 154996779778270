// @ts-nocheck

import * as anchor from "@project-serum/anchor";
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { ThirdwebProvider } from "@thirdweb-dev/react/solana";
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { PhantomWalletAdapter, UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets'
import { WalletModalProvider, WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { clusterApiUrl } from '@solana/web3.js'
import { ResetCSS } from '@pancakeswap/uikit'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import { FixedSubgraphHealthIndicator } from 'components/SubgraphHealthIndicator/FixedSubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import useEagerConnectMP from 'hooks/useEagerConnect.bmp'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import useThemeCookie from 'hooks/useThemeCookie'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Fragment, useMemo, FC, ReactNode } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { NetworkModal } from 'components/NetworkModal'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { NextPage } from 'next'
import classes from "../components/AppBar/Styles.module.css"
import { Blocklist, Updaters } from '..'
import { SentryErrorBoundary } from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'


import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import UserContextProvider from "contexts/UserContextProvider";
import { WalletBalanceProvider } from "contexts/WalletBalanceProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MusicPlayer from 'views/Pools/components/MusicPlayer'


require('@solana/wallet-adapter-react-ui/styles.css')

const WalletConnectionProvider = dynamic<{ children: ReactNode }>(
  () =>
    import("contexts/WalletConnectionProvider").then(
      ({ WalletConnectionProvider }) => WalletConnectionProvider
    ),
  {
    ssr: false,
  }
);



// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  usePollCoreFarmData()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  useThemeCookie()
  return null
}

function MPGlobalHooks() {
  usePollBlockNumber()
  useEagerConnectMP()
  usePollCoreFarmData()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  return null
}

function MyApp(props:AppProps<{ initialReduxState: any, }>) {




  // network error stopped I used this rpc to fix
  // https://api.metaplex.solana.com


  const DEFAULT_TIMEOUT = 60000;

  const { pageProps, Component, session } = props
  const store = useStore(pageProps.initialReduxState)
  const network: Network = "mainnet-beta";

  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.

  return (
     <>
    <div>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta
          name="description"
          content="COPE"
        />
        <meta name="theme-color" content="#1FC7D4" />
        <meta name="twitter:image" content="COPE" />
        <meta
          name="twitter:description"
          content="COPE"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="COPE" />
        <title>COPE</title>
        {(Component as NextPageWithLayout).mp && (
          // eslint-disable-next-line @next/next/no-sync-scripts
          <script src="https://public.bnbstatic.com/static/js/mp-webview-sdk/webview-v1.0.0.min.js" id="mp-webview" />
        )}
      </Head>{' '}
      <br /> <br /> <br /> <br />


      <WalletConnectionProvider>
      <ThirdwebProvider
        network={"https://mainnet.helius-rpc.com/?api-key=4a6b0455-3675-4e0d-bc3b-c8c338b6688d"}
        authConfig={{
          domain: "https://beta.unlimitedcope.com",
          authUrl: "https://login.unlimitedcope.com/api/auth",
          loginRedirect: "https://beta.unlimitedcope.com",
        }}
      >

        <WalletModalProvider>
          <WalletBalanceProvider>
            <UserContextProvider>







             <Providers store={store}>
               <Blocklist>
                 {(Component as NextPageWithLayout).mp ? <MPGlobalHooks /> : <GlobalHooks />}
                 <ResetCSS />
                 <GlobalStyle />
                 <GlobalCheckClaimStatus excludeLocations={[]} />
                 <PersistGate loading={null} persistor={persistor}>
                   <Updaters />
                   <App {...props}/>





                 </PersistGate>
               </Blocklist>
             </Providers>
             <Script
               strategy="afterInteractive"
               id="google-tag"
               dangerouslySetInnerHTML={{
                 __html: `
           (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
           new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
           j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
           'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
           })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTAG}');
         `,
               }}
             />

           </UserContextProvider>
         </WalletBalanceProvider>
       </WalletModalProvider>
      </ThirdwebProvider>
     </WalletConnectionProvider>


   </div>
   </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<unknown>>
  /** render component without all layouts */
  pure?: true
  /** is mini program */
  mp?: boolean
  /**
   * allow chain per page, empty array bypass chain block modal
   * @default [ChainId.BSC]
   * */
  chains?: number[]
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  if (Component.pure) {
    return <Component {...pageProps} />
  }



  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  const ShowMenu = Component.mp ? Fragment : Menu

  return (
    <ProductionErrorBoundary>

      <ShowMenu>
        <Layout>

          <Component {...pageProps} />

        </Layout>


      </ShowMenu>


      <ToastListener />
      <FixedSubgraphHealthIndicator />

    </ProductionErrorBoundary>
  )
}

export default MyApp
