import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  CommunityIcon,
  InfoIcon,
  HelpIcon,
  TeamBattleIcon,
  AccountIcon
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (t: ContextApi['t'], isDark: boolean, languageCode?: string, chainId?: number) => ConfigMenuItemsType[] =
  (t, isDark, languageCode, chainId) =>
    [

      {
        label: t('COPE Leaderboards'),
        href: '/leaderboard',
        icon: TeamBattleIcon,
        fillIcon: TeamBattleIcon,
        image: '/images/decorations/pe2.png',
        showItemsOnMobile: true,
        items: [{
          label: t('Token Trader Leaderboard'),
          href: '/leaderboard',
        },




        ].map((item) => addMenuItemSupported(item, chainId)),
      },


      {
        label: t('Community Initiatives'),
        href: '/research-and-development',
        icon: CommunityIcon,
        fillIcon: CommunityIcon,
        image: '/images/decorations/pe2.png',
        showItemsOnMobile: true,
        items: [{
            label: t('Research and Development'),
            href: '/research-and-development',
          },
          {
          label: t('Spacedrop'),
          href: '/spacedrop',
        },
        {
        label: t('Blockchain Gaming'),
        href: '/blockchain-gaming',
      },

        ].map((item) => addMenuItemSupported(item, chainId)),
      },


            {
              label: t('FAQs'),
              href: '/cope-card-key',
              icon: HelpIcon,
              fillIcon: HelpIcon,
              image: '/images/decorations/pe2.png',
              showItemsOnMobile: true,
              items: [{
                  label: t('Cope Card Key'),
                  href: '/cope-card-key',
                },
                {
                label: t('Token Trader Leaderboard'),
                href: '/token-trader-leaderboard-faqs',
              },
              {
              label: t('Token Trader Leaderboard Reports'),
              href: '/token-trader-leaderboard-reports-faqs',
            },


              ].map((item) => addMenuItemSupported(item, chainId)),
            },


            {
              label: t('COPE Chat'),
              href: '/chat',
              icon: AccountIcon,
              fillIcon: AccountIcon,
              image: '/images/decorations/pe2.png',
              showItemsOnMobile: false,
              items: [].map((item) => addMenuItemSupported(item, chainId)),
            },
















    ].map((item) => addMenuItemSupported(item, chainId))

export default config
